





























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { clearFilter } from "@/utils/base";
import { GetCenter } from "@/request/common";
import { LoginOut } from "@/request/account";
import { GetShowZhuanbingku } from "@/request/manage";
import { forIn } from "lodash";
@Component({})
export default class MainHeaderView extends Vue {
  private window: any = window;
  @Prop()
  private cState: any;
  private tempC: any = {};
  private centers: any[] = [];
  private showCenters: any[] = [];
  private userList: any = [];
  private cuUser: any = "";
  private get configuration() {
    return this.$store.state.configuration;
  }
  private get isShowZhuanbingku() {
    return this.$store.state.isShowZhuanbingku;
  }
  private get center() {
    return this.$store.state.center;
  }
  // private set center(val) {
  //   this.$store.commit("updateCenter", val);
  //   this.$router.push("/main/storehouse/case/list");
  // }
  private get user() {
    return this.$store.state.user;
  }
  private get ifLowHeight() {
    if (window.innerHeight <= 670) {
      return true;
    } else {
      return false;
    }
  }
  private ifShowPart(key: any) {
    let result: any = false;
    if (this.user["姓名"] == "admin") {
      result = true;
    } else {
      this.centers.forEach((ele: any) => {
        if (ele[key]) {
          result = true;
        }
      });
    }
    return result;
  }
  private changeCenter(center: any) {
    this.$store.commit("updateCenter", center);
    // 如果在当前页面则刷新，否则跳转页面
    if (this.cState === "专病数据库") {
      location.reload();
    } else {
      this.$router.push("/main/storehouse/case/list");
    }
  }
  private goDefault() {
    const configuration = this.$store.state.configuration;
    if (configuration["首页"].isShow) {
      this.$router.push("/main/home/home-show");
    } else if (configuration["数据中心"].isShow) {
      this.$router.push("/main/storehouse/storehouse-select");
    } else if (configuration["专病数据库"].isShow) {
      this.$router.push("/main/storehouse/storehouse-select");
    } else if (configuration["数据研究"].isShow) {
      this.$router.push("/main/research/tongue/list");
    } else if (configuration["分析挖掘"].isShow) {
      let paths: any = [];
      this.configuration["分析挖掘"].child.forEach((ele: any) => {
        if (ele.isShow) {
          paths.push(ele.path);
        }
      });
      this.$router.push(paths[0]);
    } else if (configuration["用户权限管理"].isShow) {
      this.$router.push("/main/manage/zhanghu/list");
    }
  }
  /**
   * @description 跳转专病选择页面
   */
  private goSelect() {
    localStorage.setItem("token", this.cuUser);
    this.$router.push("/main/zbsjk/zbsjk-select");
    setTimeout(() => {
      location.reload();
    }, 100);
  }
  /**
   * @description 点击切换账号
   */
  private handleCommand(command: any) {
    if (command === "基本信息") {
      this.goAccountManage();
    } else if (command === "退出登录") {
      LoginOut(this).then((res: any) => {
        localStorage.removeItem("token");
        this.$router.push("/access/login");
      });
    }
  }
  /**
   * @description 页面跳转
   */
  private goRoute(type: any, path: any) {
    clearFilter();
    if (type === "数据研究") {
      // this.jumpTongueList();
      if (this.configuration["数据研究"]["child"][1].isShow) {
        this.$router.push(this.configuration["数据研究"]["child"][1].path);
      } else {
        this.$router.push("/main/research/tongue/list");
      }
    } else if (type === "分析挖掘") {
      let paths: any = [];
      this.configuration["分析挖掘"].child.forEach((ele: any) => {
        if (ele.isShow) {
          paths.push(ele.path);
        }
      });
      this.$router.push(paths[0]);
    } else if (type === "课题管理") {
      this.$router.push("/main/topic/caijishuoming/folder");
    } else if (type === "用户权限管理") {
      this.$router.push("/main/manage/zhanghu/list");
    }
  }
  /**
   * @description 获得专病数据库列表
   */
  private getCenterList() {
    GetCenter(this).then((data: any) => {
      this.$store.commit("updateCenters", data);
      this.centers = data;
      this.showCenters = [];
      this.centers.forEach((ele: any) => {
        if (ele.show) {
          this.showCenters.push(ele);
        }
      });

      // if (this.configuration.isShowSwitch) {
      //   const params = {
      //     params: {
      //       project_id: data[0].project_id,
      //     },
      //   };
      //   GetShowZhuanbingku(this, params).then((res: any) => {
      //     this.$store.commit("updateIsShowZhuanbingku", res.show);
      //   });
      // } else {
      //   this.$store.commit("updateIsShowZhuanbingku", true);
      // }
    });
  }
  /**
   * @description 跳转
   */
  private jump(url: any) {
    this.$router.push(url);
  }
  /**
   * @description 跳转智能看舌
   */
  private jumpTongueList() {
    this.$router.push("/main/research/tongue/list");
  }
  /**
   * @description 跳转个人中心
   */
  private goAccountManage() {
    localStorage.setItem(
      "beforeAccountSource",
      JSON.stringify({
        path: this.$route.path,
        query: this.$route.query,
      })
    );
    this.$router.push({
      path: "/main/account/safe/safe-folder",
    });
  }
  /**
   * @description 跳转数据导出
   */
  private goExport() {
    localStorage.setItem(
      "beforeExportSource",
      JSON.stringify({
        path: this.$route.path,
        query: this.$route.query,
      })
    );
    this.$router.push({
      path: "/main/export/export-result",
    });
  }
  /**
   * @description 返回
   */
  private goBack(): void {
    const backSource: any = localStorage.getItem("beforeAccountSource");
    if (backSource) {
      this.$router.push(JSON.parse(backSource));
    } else {
      this.goDefault();
    }
  }
  /**
   * @description 初始化
   */
  private mounted() {
    const center = localStorage.getItem("center");
    if (center) {
      this.$store.commit("updateCenter", JSON.parse(center));
    }
    this.getCenterList();
  }
}
